<template>
  <div>
    <b-card>
    <b-row>
      <b-col
        cols="12"
        md="3"
        class="mb-1 mb-md-0"
      >

      <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      >
      <b-button size="sm" variant="flat-secondary" block class="mb-1" :to="'/apps/staff/edit/'+package.user_id+'/'">Back</b-button>
      <b-form-group
        label="Package Name"
        label-for="Package Name"
      >
        <b-form-input
          id="package_name"
          v-model="package.package_name"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Expire Date"
        label-for="expire-date"
      >
        <flat-pickr
          v-model="package.expire_at"
          class="form-control"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-form-group>
      <b-form-group
        label="Price"
        label-for="Price"
      >
        <b-form-input
          id="price"
          v-model="package.amount"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Quantity"
        label-for="Quantity"
      >
        <b-form-input
          id="quantity"
          v-model="package.quantity"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Balance"
        label-for="Balance"
      >
        <b-form-input
          id="balance"
          v-model="package.balance"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Remarks"
        label-for="remarks"
      >
        <b-form-textarea
          id="remarks"
          rows="6"
          readonly
          v-model="package.remarks"
          trim
        />


      </b-form-group>
        <b-button size="sm" variant="primary" block class="mb-1" @click="save()" v-if="$can('read', 'staff')">Save</b-button>
    </b-form>
    </b-col>
      <b-col
        cols="12"
        md="9"
        class="mb-1 mb-md-0 py-2"
      >
    <b-row>
      <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start "
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="12"
      >
      <b-table
        ref="selectableTable"
        selectable
        v-if="package.package_type == 'PACKAGE'"
        :select-mode="selectMode"
        :items="items"
        :fields="fields"
        responsive
        striped
        class="mb-0"
        @row-selected="onRowSelected"
      >

        <template #cell(status_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-table
        ref="selectableTable"
        selectable
        v-if="package.package_type == 'CLASS'"
        :select-mode="selectMode"
        :items="items"
        :fields="fields_class"
        responsive
        striped
        class="mb-0"
        @row-selected="onRowSelected"
      >

        <template #cell(status_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>


      </b-col>
      <b-col
        cols="12"
        md="12"
      >
      <b-row>
        <b-col class="mt-1">
          Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="right"
            size="md"
            class="my-1"
            @change="handleChangePage"
          />
        </b-col>
      </b-row>
    </b-col>
    </b-row>
    <!-- table -->
  </b-col>
</b-row>
  </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BAvatar, BCard, BCardBody, BBadge, BRow, BCol, BFormSelect, BPagination, BInputGroup,BFormTextarea, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend, BForm, BFormGroup,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    ToastificationContent,
    BForm,
    BTable,
    flatPickr,
    BAvatar,
    BBadge,
    BRow,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      package:null,
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Staff', value: 'staff' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Disabled', value: 'DISABLED' },
      ],
      centerOptions: [],
      isAddNewStaffSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'id', label: '#' }, { key: 'date', label: 'Date' }, { key: 'start', label: 'Start Time' }, { key: 'end', label: 'End Time' }, { key: 'method', label: 'Method' }, { key: 'created_at', label: 'Created at' }, { key: 'is_attend', label: 'Attended' }],
      fields_class: [{ key: 'id', label: '#' }, { key: 'class_date', label: 'Date' }, { key: 'time', label: 'Time' }, { key: 'method', label: 'Method' }, { key: 'created_at', label: 'Created at' }, { key: 'is_attend', label: 'Attended' }],

      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
  },
  methods: {
    one() {
      this.isAddNewStaffSidebarActive = false
      this.get_data()
    },
    save() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/staff_package/`+ router.currentRoute.params.id + `/update/`,this.package)
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.password = null
               this.$refs['my-modal'].hide()
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
             }
        })
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staff_package/`+ router.currentRoute.params.id + `/?perpage=`+this.perPage+`&page=`+this.currentPage)
        .then(res => {
          this.items = res.data.records
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          this.package = res.data.package
          //  console.log( res.data.members.length)
        })
    },

    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items
      console.log(items)
    //  this.$router.push(`/apps/staff/edit/${items[0].user_id}/`)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
